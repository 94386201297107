











































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class OrderDetails extends Vue {
  loading: boolean = false; //loading
  id!: any; //代理iD
  recordImg: any = {
    //提交数据
    bargain: "", //合同正面
    bargainAgainst: "", //合同背面
    identity: "", //身份证正面
    identityAgainst: "", //身份证反面
    businessLicense: "", //营业执照副本
  };
  pagerHeader: any = {
    //页面头部
    title: "代理档案",
    desc: "代理档案",
    breadcrumb: ["代理列表", "代理档案"],
  };
  //   async readerUserInfo() {
  //     this.loading = true;
  //     try {
  //       var res = await this.$ajax.get("/api/services/app/Member/GetMemberInfo", {
  //         params: {
  //           id: this.id,
  //           includeAgent: true,
  //           includeMerchants: true,
  //           StatisticTodayProfit: true,
  //         },
  //       });
  //     } catch (error) {}
  //     this.loading = false;
  //   }
  handleAvatarSuccess(res: any, file: any) {
    this.recordImg.bargain = URL.createObjectURL(file.raw);
  }
  created() {
    this.id = this.$route.query.id;
    this.getImg();
  }
  async getImg() {
    try {
      var res = await this.$ajax.get(
        "/api/services/app/Merchant/GetArchiveInfo",
        {
          params: {
            id: this.id,
          },
        }
      );
      if (res.data.success) {
        (this.recordImg.bargain = res.data.result.contractFImage), //合同正面
          (this.recordImg.bargainAgainst = res.data.result.contractBImage), //合同背面
          (this.recordImg.identity = res.data.result.idCardFImage), //身份证正面
          (this.recordImg.identityAgainst = res.data.result.idCardBImage), //身份证反面
          (this.recordImg.businessLicense =
            res.data.result.businessLicenseImage); //营业执照副本
      }
    } catch (error) {}
  }
  activated() {
    if (this.id != this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getImg();
    }
  }
}
